'use client';

import { useEffect } from 'react';

import ErrorFallback from './_components/error-boundary-fallback/ErrorBoundaryFallback';

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

export default function Error({ error }: ErrorPageProps) {
  useEffect(() => {
    console.error('Error:', error);
  }, [error]);

  return (
    <div>
      <ErrorFallback error={error} />
    </div>
  );
}
